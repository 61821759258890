(function($, undefined) {

window.bindFavourites = function() {
	$("a[data-rel='favourites']").bind('click', function(event){

		var ajaxurl		= this.href,
			self 		= $(this),
			entryId 	= this.getAttribute('data-entryId'),
			isFav 		= self.hasClass('active');

		// Toggle
		if(isFav) {
			ajaxurl = ajaxurl + '/delete/';
		}

		$.ajax({
			url: ajaxurl,
			type: 'GET',
			success: function(data) {
				if(isFav) {
					self.removeClass('active');
				}
				else {
					self.addClass('active');
				}
				alert(data);
			}
		});
		$(this).parents("article.favourite-entry").hide();

		return false;
	});

};

window.bindFavourites();

})(jQuery);