(function($, undefined) {

    var overlay = $('<div id="page-overlay">&nbsp;</div>').insertAfter('#header');

    if (!Modernizr.touch) {
        $('nav.nav-site li.dropdown').hover(function() {
            overlay.addClass('active');
        }, function() {
            overlay.removeClass('active');
        });
    }


    // $("div.nav-content a").bigTarget({
    //     hoverClass: 'bigTargetHover',
    //     clickZone : 'div:eq(0)'
    // });

    // Show & Hide the member area
    $('nav.nav-account').each(function(){

    	// Setup the toolbar / container
    	var $toolbar = $("#toolbar #toolpanel")
    						.hide()
    						.container({ animate: true })
    						.bind('containershowelement', function(){
    							//console.log("triggered containershowelement");
    						})
    						.bind('containerdestroyelement', function(){
    							//console.log("triggered containerdestroyelement");
    						});

    	var $closeBtn =  $('<a href="#" class="close-btn">close</a>').appendTo($toolbar);

    	// setup the ajax triggers
    	$('[data-ajaxUrl]').click(function(event) {
    		var $self = $(this),
    			ajaxUrl = $self.attr("data-ajaxUrl");

    		// remove the 'active' class from any anchors
    		$('li a', 'nav.nav-account').each(function() {
    			var $li = $(this).removeClass('active');
    		});

    		// if the element is already loaded
    		// just show the ID
    		if($self.data('loaded')) {
    			$toolbar
    				.container('hideAllElements')
    				.container('showElement', ajaxUrl);
    			$('#head').animate({height: '110'});
    			// Add class of 'active' to the anchor
    			$self.addClass('active');
    			return false;
    		}


    		$.ajax({
    			url: ajaxUrl,
    			type: 'GET',
    			dataType: 'html',
    			complete: function(xhr, textStatus) {
    				//console.log("complete");
    			},
    			success: function(data, textStatus, xhr) {
    				//console.log('success');
    				$self.data("loaded", true);
    				// Add class of 'active' to the anchor
    				$self.addClass('active');
    				$el = $(data).hide();
    				$toolbar
    					.container('hideAllElements')
    					.container('pushElement', $el, ajaxUrl)
    					.container('showElement', ajaxUrl)
    					.container('open');
    				$('#head').animate({height: '110'});

    				$('#toolpanel input[type=text]').blur(function() {
    				    $(document).trigger('notEmptyField', this);
    				});

					$('#toolbar form').each(function(index) {
						window.buildValidator($(this));
					});
					window.bindFavourites();
    			},
    			error: function(xhr, textStatus, errorThrown) {
    				//console.log("error");
    			}
    		});

    		return false;

    	})
    	.data('loaded', false);

    	$closeBtn.click(function(){
    		$toolbar.container('close');
    		$('#head').animate({height: '144'});

    		$('li a', 'nav.nav-account').each(function() {
    			var $li = $(this).removeClass('active');
    		});
    	});

    	$("[data-containerMethod]").click(function(event) {
    		var method = $(this).attr("data-containerMethod");
    		$toolbar.container(method);
    	});
    });
})(jQuery);
