function validate_form(id, options){
	idx = "#"+$(id).attr("id");
	var opts = $.extend({
		errorClass: "error"    
	}, options);
	return $(idx).validate(opts);
}

$.validator.addMethod(
	"changePassword",
	function(value, element) {
		form = $(element).parentsUntil('form');
		var pass1 = form.find('#form-account-editpassword').val();
		var pass2 = form.find('#form-account-editpassword_confirm').val();
		if (pass1 != "" || pass2 != "") {
			return (pass1 != "" && pass2 != "" && pass1 == pass2) ? true : false;
		} else {
			return true;
		}
	},
	"Your chosen password does not match your confirmed password"
);

$.validator.addMethod(
	"confirmNewPassword",
	function(value, element) {
		form = $(element).parentsUntil('form');
		var pass1 = $(form).find('input[name="password"]').val();
		var pass2 = $(form).find('input[name="password_confirm"]').val();
		return (pass1 == pass2) ? true : false;
	},
	"Your chosen passwords do not match or are not valid"
);

window.buildValidator = function(elem) {
	inputRules = {};
	inputMessages = {};
	$(elem).find('.required').each(function(index) {
		var inputName = $(this).attr("name");
		switch (inputName) {
			case "email":
				inputRules[inputName] = {email:true, required:true};
				break;
			case "username":
				inputRules[inputName] = {required:true, minlength:5};
				break;
			case "password":
				inputRules[inputName] = {required:true, minlength:5};
				break;
			case "password_confirm":
				inputRules[inputName] = {required:true, minlength:4, confirmNewPassword:true};
				break;
			case "subscribe-email":
				inputRules[inputName] = {email:true, required:true};
				break;
			case "current_password": 
				inputRules[inputName] = {required:true, minlength:5};
				break;
			default :
				inputRules[inputName] = {required:true};
				inputMessages[inputName] = ($(this).attr('title') != "") ? $(this).attr('title') : "Please enter" + $(this).prev().text();
			break;
		};
		inputRules['password'] = {changePassword:true};
	});
	var register_form_validator = validate_form(elem, {rules:inputRules, messages:inputMessages});
};

$(document).ready(function() {
	$('form').each(function(){
		buildValidator(this);
	});
});