
(function($, undefined) {

	Shadowbox.init({
		handleOversize: "drag",
		overlayOpacity: 0.7,
		displayNav: true,
		flv: ['flv','mp4']
	});

	// lazy load ajax nav content
	var $lazyload_objs = $('[data-ajax-content]').each(function(){
		var $self = $(this);
		$.ajax({
			url: $self.attr('data-ajax-content'),
			type: 'GET',
			dataType: 'html',
			complete: function(xhr, textStatus) {
				//console.log("complete");
			},
			success: function(data, textStatus, xhr) {
				$self.append(data);
			},
			error: function(xhr, textStatus, errorThrown) {
				//console.log("error");
			}
		});
	});

	// call lazy loading of publication meta
	$(".entry-meta[data-load-publication-meta]").NSM_publicationMeta();

	// Change Topic Nav to toggle on click for mobile (Instead of :hover)
	$(".topic-nav").on('click', function(e) {
	    $(".category-title-index").toggle();
		$(".topic-nav div").toggleClass( "is-open" );
	    e.preventDefault();
	});

	$(".topic-nav a").on('click', function(e) {
		e.stopPropagation();
	});

	// lazy load category counts
	$('div[data-ajax-category-counts]').each(function(){
		var $self = $(this);
		var $new_content = $self.clone();
		var $categories = $new_content.find('a[data-category-id]');
		if($categories.length > 0){
			// get entry ids
			var ajaxUrl = $self.attr('data-ajax-category-counts');
			// get the publication details on entry ids
			$.ajax({
				url: ajaxUrl,
				type: 'GET',
				dataType: 'json',
				complete: function(xhr, textStatus) {
					//console.log("complete");
				},
				success: function(data, textStatus, xhr) {
					$categories.each(function(i){
						var category_id = $categories.eq(i).attr('data-category-id');
						if(typeof(data[ category_id ]) !== 'undefined' ){
							var html = decodeURIComponent(data[ category_id ]);
							$categories.eq(i).append( html );
						}
					});
					$self.replaceWith( $new_content );
				},
				error: function(xhr, textStatus, errorThrown) {
					//console.log("error");
				}
			});
		}
	});

	// manage outbound hyperlinks
	var $outbound_links = $("a[data-trackOutbound='true']");
	// if($outbound_links.length > 0){
		$outbound_links.bind('click', function(){
			var outbound_label = $(this).attr('data-outboundLabel');
			//console.log("_gaq.push(['_trackEvent', 'Premium Product', 'External Link', '"+outbound_label+"']);");
			_gaq.push(['_trackEvent', 'Premium Product', 'External Link', outbound_label]);
			//return false;
		});
	//}

	$('form fieldset.filter-pane').hide();

	// This is specific to the advanced search form.
	// Get the collection select input and process it
	$("form select[name='collection']").each(function(){
		// prepare our variables first
		var $t = $(this);
		var this_form = $t.parents('form');
		var filters = this_form.find('fieldset.filter-pane');
		var selected = $t.find('option:selected').attr('rel');

		// this function changes tab based on selected option's rel attribute
		function collection_select_change(){
			var selected = $t.find('option:selected').attr('rel');
			filters.hide();
			this_form.find('#filter-'+selected).fadeOut('slow', function(){
				filters.find(':text').val('')
				.find(':checkbox, :radio').removeAttr('checked')
				.find('select option').removeAttr('selected');
				this_form.find('#filter-'+selected).fadeIn('slow', function(){
					$(this).show('1000');
				});
			});
		}

		// run the function to swap to the correct tab
		collection_select_change();
		// add a bind to the select object to update tabs
		$t.bind('change', function(){
			collection_select_change();
		});
	});


	$(".comment-index article:nth-child(odd)").addClass("odd");

	$(".page-entry > .entry-content").nsmToc({
		generate_toc:		true,
		header_selector:	":header:visible",
		toc_el:				".js-toc-module",
		toc_title:			false,
		start_depth:		2,
		end_depth: 			3,
		append_top_links:   false,
		complete: function(data){

			// this site doesn't use contentcreated so this is OK for now
		    var $sticky_toc_el = $('.js-toc-sticky'),
		        $toc = $(data.toc);

			// this callback is only fired once per `.page-entry` and nsmToc appends TOC to $(o.toc_el) so be simple.
            if($toc.find("li").length === 0) {
            	var $toc_parents = $(data.toc_el);

				// using variable to hold the parents because Chrome debugging isn't able to figure out variable scoping
                $toc_parents.remove();

                return;
            }

            var $window = $(window),
                sticky_toc_top = $sticky_toc_el.offset().top,
                fixed = false,
                $clone = $sticky_toc_el.clone().insertAfter($sticky_toc_el).hide();

            $window.scroll(function(){
                if($window.scrollTop() >= sticky_toc_top) {
                    if(fixed === false) {
                        $sticky_toc_el.addClass('fixed');
                        $clone.show();
                    }
                }
                else {
                    $sticky_toc_el.removeClass('fixed');
                    $clone.hide();
                    fixed = false;
                }
            });
        }

	});

	/* Show/Hide Mobile Navigation */
    $(".js-module-title").on('click', function(e) {
        e.preventDefault();

        $(".js-toc-module").toggleClass('is-active');

        // if ($('.js-primaryNav').hasClass('is-active')) {
        //     hideMobileNav();
        // } else {
        //     showMobileNav();
        // }
    });


	$("form.form-account-edit input").focusin(function(){
		$(this).parent().find("div.note").css({"position":"absolute","left":100,"top": $(this).position().top}).fadeIn();
	}).focusout(function() {
		$(this).parent().find("div.note").fadeOut();
	});


	$("div.bookmarks-list a[data-rel='favourites_del']").bind('click', function(){
		var ajaxurl = this.href;
		var self = this;
		$.ajax({
			url: ajaxurl,
			type: 'GET',
			dataType: 'html',
			complete: function(xhr, textStatus) {
				//console.log("complete");
			},
			success: function(data, textStatus, xhr) {
				$(self)
					.parents('div.entry-favourite')
					.remove();
					alert(data);
				},
			error: function(xhr, textStatus, errorThrown) {
				//console.log("error");
			}
		});
		return false;
	});

	$(".form-newsletter-subscribe").each(function() {
		var $container = $(this);
		var $trigger = $('.change-subscription-options', $container).addClass('collapsed');
		var $target = $('.subscription-options', $container).hide();

		$trigger.each(function() {

			var $self=$(this);

			$self.click(function(){
				if($self.is('.collapsed')) {
					$self
						.removeClass('collapsed')
						.addClass('expanded');
					$target.show();
				} else {
					$self
						.removeClass('expanded')
						.addClass('collapsed');
					$target.hide();
				}
				return false;
			});
		});
	});

    /**
     * This only applies to the topics index where a topic is grouped into multiple channels
     */
	$('.tg-topics ul.filter-by-publication-type').each(function() {
		var self = $(this);
        // Dynamically build the filter
		$("div.entry-index-entries h3.category-entry-title > a.entry-details-url").reverse().each(function() {
			var listItem = '<li class="publication"><a href="#'+$(this).parent().parent().attr('id')+'" title="Filter by group">'+$(this).attr('title')+'</a></li>';
			$(listItem).prependTo(self);
		});
	});

    // we don't want this applied to the contributors
    $('.index-filter:not(".filter-contributor")').each(function(index) {
        $(this).find('ul').tabs();
    });

    $('.index-filter-anchor').unbind('click');

	var advancedSearchActive = false;
	$('.advanced-search-toggle').click(function(){
		if(advancedSearchActive === false) {
			$('.advanced-search-toggle').text('Simple Search');
			$('.advanced-search-options').addClass('active');
			advancedSearchActive = true;
		}
		else {
			$('.advanced-search-toggle').text('Advanced Search');
			$('.advanced-search-options').removeClass('active');
			advancedSearchActive = false;
		}
		return false;
	});

	$transitionals = $('.js-transitionalTrigger').flyoutTrigger();
	$drilldowns = $('.js-drillDownMenu').drillDownMenu();

	console.log("Intitialising Homepage slider!");

	$('.js-homepage-promos').removeClass("hide-non-first");

	$('.js-homepage-promos').slick({
		autoplay: true,
		autoplaySpeed: 8000,
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		fade: true,
		slide: '.entry',
		asNavFor: '.js-slick-promo-slave'
	});

	$('.js-homepage-backgrounds').removeClass("hide-non-first");

	$('.js-homepage-backgrounds').slick({
		dots: false,
		arrows: false,
		infinite: true,
		speed: 1500,
		fade: true
	});

	$('.js-homepage-backgrounds-credits').removeClass("hide-non-first");

	$('.js-homepage-background-credits').slick({
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		fade: true
	});

})(jQuery);
