$(window).bind('load',function(){
   var
       subscribeState = $("#subscribe-state"),
       replaceOptions = $("#replace-options"),
       states = $('#states'),
       lis = states.find('li'),
       optionsActive = false,
       replaceOptionsLabel = $("#replace-options-label");

	subscribeState.hide();
	replaceOptions.show();

    if (Modernizr.touch) {
        replaceOptions.bind('click',function(){
            if(optionsActive){
                optionsActive = false;
                states.hide();
            } else {
                optionsActive = true;
                states.show();
            }
        });
    } else {
        replaceOptions.bind('mouseover',function(){
    		states.show();
    	});

    	replaceOptions.bind('mouseout',function(){
    		states.hide();
    	});
    }
   lis.bind('click', function(){
		var $t = lis.filter(this);
		var selected = $t.attr("rel");
       subscribeState.val(selected);
       states.hide();
       replaceOptionsLabel.html( $t.html() );
   });
});
