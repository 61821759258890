// Controls Purchase Functionality
$(function()
{	

	// Main purchase form
	var purchase_target = $('.purchase-form');
	
	// Additional purchases onClick handler
	$('.more-premium-content').click(function(e){
		e.preventDefault();
		
		if ( !$(this).hasClass("selected") ) {
			
			// Hide elements
			// hide_purchase_details();
			
	        //Add selected class to buy additional btn
			$(this).toggleClass('selected');
			
			// Update text to display close message
			$(this).text("Close this panel");
			
			// Begin Element display
			$(purchase_target).show();
			// display_purchase_details();
		}
		else{
	        //Remove selected class to buy additional btn
			 $(this).toggleClass('selected');
			
			// Update text to display message
			$(this).text("Buy additional copies?");
			
			// Begin Element remove
			$(purchase_target).hide();
		}
	});	
});
