(function() {

	var scrollHandler = function(event) {
		var contentBox = event.data.contentBox,
            contentBottom = contentBox.offset().top + contentBox.height(),
            windowBottom = $(window).scrollTop() + $(window).height();
		if (windowBottom > contentBottom && !contentBox.data('ajaxIsLoading')) {
			appendMoreAjaxResults(contentBox);
		}
	}
	
	var clickHandler = function(event) {
		event.preventDefault();
		appendMoreAjaxResults(event.data.contentBox);
	}
	
	$("div[data-content-src]").each(function() {
		var contentBox = $(this);
		$('p.pagination').hide();
		contentBox.data('ajaxIsLoading' , false);
		//contentBox.find(".entry-meta[data-load-publication-meta]").NSM_publicationMeta();

		// we need to check if there is a problem with the content of this element or this will not work and JS error.
		var contentBoxElements = contentBox.find(contentBox.attr('data-content-elements'));
		if (0 === contentBoxElements.length) {

			// just stop as there is nothing that can be paginated anyway
			return;
		}
		
		if (contentBox.attr('data-load-method') == "scroll") {
			$(window).bind('scroll', {contentBox: contentBox}, scrollHandler);
		} else {
			var entryCounts = contentBoxElements.last().attr("data-entry-count").split('/'),
				lastResult = parseInt(entryCounts[0]),
				totalResults = parseInt(entryCounts[1]),
				contentSrc = contentBox.attr('data-content-src'),
                nextPageUrl = (contentSrc ? contentSrc + "/P" + lastResult : '#');
			if (lastResult < totalResults) {
				var link = '<a href="' + nextPageUrl + '" class="loadMore">View More</a>';
				$(link).appendTo(contentBox).bind('click', {contentBox: contentBox}, clickHandler);
			}
		}
	});

	function appendMoreAjaxResults(contentBox) {
	   var contentBox = contentBox,
			ajaxurl = contentBox.attr('data-content-src'),
			blockClass = contentBox.attr('data-content-elements'),
			ajaxThrobber = '<div class="ajaxLoading"></div>',
			entryCounts = contentBox.find(blockClass).last().attr("data-entry-count").split('/'),
			lastResult = parseInt(entryCounts[0]),
			totalResults = parseInt(entryCounts[1]);

		if (lastResult < totalResults) {
			contentBox.data('ajaxIsLoading' , true);
			contentBox.append(ajaxThrobber);
			$.ajax({
				url: ajaxurl + "/P" + lastResult,
				type: 'GET',
				dataType: 'html',
				success: function(data, textStatus, xhr) {
					contentBox.data('ajaxIsLoading' , false);
					var lastEntry = $(contentBox).find(blockClass).last(),
						newData = $(data).find(blockClass);

					$(newData).insertAfter(lastEntry).hide().fadeIn(1000);
					
					$(contentBox).find('div.ajaxLoading').slideUp(500, function() {
						$(this).remove();
					});
					$('p.pagination').hide();

					var entryCounts = contentBox.find(blockClass).last().attr("data-entry-count").split('/'),
						lastResult = parseInt(entryCounts[0]),
						totalResults = parseInt(entryCounts[1]),
                        nextPageUrl = (ajaxurl ? ajaxurl + "/P" + lastResult : '#');
					if (lastResult == totalResults) {
						$(contentBox).find('a.loadMore').hide();
					} else {
						$(contentBox).find('a.loadMore').attr('href', nextPageUrl).show();
					}
					contentBox.find(".entry-meta[data-load-publication-meta]").NSM_publicationMeta();
				}
			});
		}
	}
	
	jQuery.fn.reverse = function() {
	    return this.pushStack(this.get().reverse(), arguments);
	};


})(jQuery);